<template>
  <div class="user-form">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t(`users.form.header.${user ? 'edit' : 'new'}`) }}
        </p>
      </header>
      <section class="modal-card-body">
        <form ref="form">
          <div
            v-if="!user"
            class="alert-info">
            {{ $t('users.form.tips.existingSpineUser') }}
          </div>
          <div class="columns is-mobile">
            <div class="column is-half">
              <b-field :label="$t('users.form.role')"/>
              <users-role-selector :value.sync="form.type"/>
            </div>
            <div class="column is-half">
              <b-field :label="$t('users.form.hubs')"/>
              <users-hub-selector
                :value.sync="form.hubIds"
                multiple
                :disabled="isCustomerCareSelected"/>
              <p
                v-if="isCustomerCareSelected"
                class="customer-care-selected-tip">
                {{ $t('users.form.tips.customerCare') }}
              </p>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column is-half">
              <b-field :label="$t('users.form.name')">
                <b-input v-model="form.name"/>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field :label="$t('users.form.email')">
                <b-input
                  v-model="form.email"
                  type="email"/>
              </b-field>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column is-half">
              <b-field :label="$t('users.form.mobileNumber')">
                <b-input
                  v-model="form.mobileNumber"
                  type="tel"
                  pattern="[+]?[0-9]{8,}"
                  validationMessage="Please enter a valid mobile number"
                  title="Please enter a valid mobile number"/>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field :label="$t('users.form.password')">
                <b-input
                  v-model="form.password"
                  type="password"
                  autocomplete="new-password"/>
              </b-field>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <b-button
          type="is-primary"
          expanded
          @click="createUpdateAccount">
          {{ $t(`common.actions.${user ? 'update' : 'create'}`) }}
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { USER_ROLES } from '@js/constants'
import { mapGetters } from 'vuex'

const UsersRoleSelector = () => import('@components/UsersRoleSelector')
const UsersHubSelector = () => import('@components/UsersHubSelector')

export default {
  name: 'user-form',
  components: {
    UsersRoleSelector,
    UsersHubSelector
  },
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      form: {}
    }
  },
  computed: {
    ...mapGetters('hub', [
      'hubIds'
    ]),
    isCustomerCareSelected() {
      return this.form.type === USER_ROLES.CUSTOMER_SPECIALIST
    }
  },
  watch: {
    'form.type'() {
      if (this.isCustomerCareSelected) {
        this.$set(this.form, 'hubIds', this.hubIds)
      }
    }
  },
  created() {
    if (this.user) {
      this.form = {
        id: this.user.id,
        type: this.user.role,
        hubIds: this.user.authorizedHubIds.concat(),
        userGroup: this.user.userGroupName,
        name: this.user.name,
        email: this.user.email,
        mobileNumber: this.user.mobileNumber
      }
    } else {
      this.form = {
        hubIds: []
      }
    }
  },
  methods: {
    createUpdateAccount() {
      const form = this.$refs.form
      if (form.checkValidity()) {
        if (this.user) {
          this.$emit('update', this.form)
        } else {
          this.$emit('create', this.form)
        }
      } else {
        form.requestSubmit()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  form {
    padding-bottom: $space-l;
  }

  .alert-info {
    padding: $space-xs;
    margin-bottom: $space-m;
    color: $info-dark;
    background-color: $info-light;
  }

  .field {
    margin-bottom: 0;

    &::v-deep {
      .label,
      .input {
        @extend %body;
      }

      .label {
        margin-bottom: $space-xxs;
      }
    }
  }

  .customer-care-selected-tip {
    margin-top: $space-xxs;
    @extend %small;
  }
}
</style>
